import React, { useEffect, useMemo, useState, lazy, Suspense } from "react";
import { StyledEngineProvider, ThemeProvider, createTheme, useTheme } from '@mui/material/styles';
import { registerLicense } from "@syncfusion/ej2-base";
import { BrowserRouter as Router, useHistory } from "react-router-dom";
import { Provider, connect } from "react-redux";
import { store, persistor } from "./redux/store";
import "./library/scss/index.scss";
import { ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { PersistGate } from "redux-persist/integration/react";
import { history } from "../src/components/Helpers";
import "../node_modules/@syncfusion/ej2-base/styles/material.css";
import "../node_modules/@syncfusion/ej2-inputs/styles/material.css";
import "../node_modules/@syncfusion/ej2-buttons/styles/material.css";
import "../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css";
import "../node_modules/@syncfusion/ej2-lists/styles/material.css";
import "../node_modules/@syncfusion/ej2-navigations/styles/material.css";
import "../node_modules/@syncfusion/ej2-popups/styles/material.css";
import "../node_modules/@syncfusion/ej2-dropdowns/styles/material.css";
import "../node_modules/@syncfusion/ej2-grids/styles/material.css";
import "../node_modules/@syncfusion/ej2-react-spreadsheet/styles/material.css";
import { LicenseInfo } from '@mui/x-license';
import { removeLock } from "./redux/actions";
const RoutesApp = lazy(() => import('./components/Routing/Routes'));
const IdleTimerContainer = lazy(() => import('../src/components/Helpers/IdleTimerContainer'));
LicenseInfo.setLicenseKey('27d104ec922418f1540254f56ca7669bTz0xMDIxNTcsRT0xNzYzNzM5NDg2MDAwLFM9cHJlbWl1bSxMTT1zdWJzY3JpcHRpb24sUFY9aW5pdGlhbCxLVj0y');
//registerLicense("Ngo9BigBOggjHTQxAR8/V1NHaF1cWWhIfEx1RHxQdld5ZFRHallYTnNWUj0eQnxTdEZiW39ZcHVXT2NVUUV1Ww==")
registerLicense("Ngo9BigBOggjHTQxAR8/V1NBaF5cXmZCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdnWXxccnRSR2FZUEFyV0M=")

const App = (props) => {
  const domHistory = useHistory()
  const [primary, setPrimary] = useState("#1976d2");
  const [primaryDark, setPrimaryDark] = useState("#1976d2");
  const [h3, setH3] = useState("1.1em");
  const [tabCount, setTabCount] = useState(0);
  const white = "#FFFFFF";
  const black = "#000000";

  useEffect(() => {
    if (props.profileData && props.profileData.color_theme) {
      localStorage.setItem("color_theme", props.profileData.color_theme);

      setPrimaryDark(props.profileData.color_theme);
    }
    if (props.profileData && props.profileData.global_color) {
      localStorage.setItem("navclr", props.profileData.global_color);

      setPrimary(props.profileData.global_color);
    }
  }, [props.profileData]);

  function validateHexColorCode(hexCode) {
    return /^#([0-9A-Fa-f]{3}){1,2}$/i.test(hexCode);
  }
  const h4 = "0.90 rem";
  const font = `Roboto Slab, serif`
  const lightTh = useMemo(
    () =>
      createTheme({
        palette: {
          primary: {
            main: validateHexColorCode(primaryDark) ? primaryDark + "!important" : sessionStorage.getItem('application') == 'Promotions' ? '#FFC400 !important' : "#445AC7 !important",
            dark: validateHexColorCode(primaryDark) ? primaryDark : "#445AC7",
          },
          secondary: {
            main: "#FBFBFB",
          },
          icon: {
            active: "#B4BDE9",
          },
          text: {
            primary: "#000000",
            grey: "#828282",
            helper: "#4F4F4F",
            yellow: "#F2F5A9",
          },
          table: {
            row: "rgba(242, 242, 242, 0.3)",
            border: "#828282",
          },
          border: {
            main: "#E0E0E0",
            divider: "#7F8CC4",
          },
          button: {
            red: "#EB5757",
            green: "#27AE60",
          },
          tabs: {
            tabActive: "#858AA3",
            text: "#ECEFF8",
          },
          black,
          white,
          gradient: {
            appLauncher: "linear-gradient(135deg, #32009A 0%, #abceff 100%)",
          },
          appLauncher: {
            tabStyle: "#32009A",
            tabStyleFont: "white",
            tabBackground: "linear-gradient(135deg, #819FF7 0%, #FFF 100%)",
            tabContainer: "#FFFFFF",
            paper: "#32009A",
          },
          notification: {
            grey: "#DADFF2",
          },
        },
        typography: {
          fontFamily: `Roboto Slab, serif`,//Roboto Slab, serif !important
          h1: {
            color: primaryDark,
            fontSize: "1.3em !important",
            fontWeight: 200,
          },
          h2: {
            color: primaryDark,
            fontSize: "1.2em !important",
          },
          h3: {
            color: primaryDark,
            fontSize: "1.1em !important",
          },
          h4: {
            color: primaryDark,
            fontSize: "0.90em !important", //".75eim",
          },
          h5: {
            color: primaryDark,
            fontSize: "0.8em !important",
          },
          h6: {
            fontSize: 13,
            color: primaryDark,
          },
          h7: {
            color: primaryDark,
            fontSize: "1em !important",
          },
          h8: {
            fontSize: 14
          },
          subtitle1: {
            color: primaryDark,
            // fontSize: 36,
          },
          button: {
            textTransform: "none",
          },
          caption: {
            color: "black",
          },
        },
        mixins: {
          MaterialHeader: {
            whiteSpace: 'nowrap',
            // fontSize: h4 + '!important',
            fontSize: '.90rem',
            padding: '5px 2px',
            textAlign: 'center',
            color: 'white',
            backgroundColor: primaryDark,
            fontFamily: font,
            borderRight: "1px solid #EEEEEE",
          },
          MaterialCell: {
            fontFamily: font,
            fontSize: h4 + '!important',
            textAlign: "center",
            borderRight: "1px solid #EEEEEE",
            color: "black !important",
            padding: '5px 0px',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            maxWidth: 150,
            width: 80,
            minWidth: 50,
          },
        },
        // overrides: {
        //   // Override styles for material-table components
        //   MuiTable: {
        //     root: {
        //       backgroundColor: primaryDark + "!important",
        //       color: "white",
        //       fontSize: "0.90em"
        //     },
        //   },
        //   MuiTableCell: {
        //     root: {
        //       border: "1px solid #EEEEEE",
        //       borderBottomWidth: "1px",
        //       padding: 7,
        //       fontSize: '0.90em'
        //     },

        //     head: {
        //       backgroundColor: primaryDark + "!important",
        //       color: "white",
        //       fontSize: "0.90em",
        //     },
        //   },
        //   MuiButton: {
        //     root: {
        //       padding: '6px 16px !important',
        //       '&:hover': {
        //         backgroundColor: primaryDark,
        //         color: white,
        //         //border: '1px solid ' + primaryDark,
        //         // change this to your desired hover color
        //       },
        //     },
        //     containedPrimary: {
        //       backgroundColor: primaryDark,
        //       padding: '6px 16px !important',
        //       color: white + "!important",
        //       '&:hover': {
        //         backgroundColor: primaryDark,
        //         //color: primaryDark,
        //         border: '1px solid ' + black,

        //         //backgroundColor: 'white',
        //         //color:primaryDark,
        //         outlineColor: 'transparent',
        //         outlineStyle: 'solid',
        //         boxShadow: '0 0 0 6px transparent',
        //         transition: '0.7s',
        //       },
        //     },
        //     outlinedPrimary: {
        //       color: primaryDark,
        //       border: '1px solid ' + primaryDark,
        //       padding: '6px 16px !important',

        //       '&:hover': {
        //         //color: primaryDark,
        //         border: '1px solid ' + primaryDark + "!important",
        //         outlineColor: 'transparent',
        //         outlineStyle: 'solid',
        //         boxShadow: '0 0 0 6px transparent',
        //         transition: '0.7s',
        //       },
        //     }
        //   },
        //   MuiFab: {
        //     root: {
        //       color: '#FFFFFF !important',
        //       border: '1px solid ' + primaryDark + ' !important'
        //     }
        //   }
        // },
        components: {
          MuiTypography: {
            styleOverrides: {
              root: {
                fontSize: '1em',
                padding: '4px',

                // height: 'initial',
                //alignContent: 'center'
              },
            }
          },
          MuiTableCell: {
            styleOverrides: {
              root: {
                border: "1px solid #EEEEEE",
                borderBottomWidth: "1px",
                padding: 7,
                fontSize: '0.90em'
              },

              head: {
                backgroundColor: primaryDark + "!important",
                color: "white !important",
                fontSize: "0.90em",
              },
            }
          },
          MuiTableHead: {
            styleOverrides: {
              root: {
                backgroundColor: primaryDark + "!important",
                color: "white !important",
                fontSize: "0.90em !important"
              },
            },
          },
          MuiIconButton: {
            styleOverrides: {
              root: {
                padding: "2px !important"
              }
            }
          },
          MuiTable: {
            styleOverrides: {
              root: {
                marginTop: 16,
                borderBottom: "1px solid #EEEEEE",
                padding: 7,
              },
            }
          },
          MuiButton: {
            styleOverrides: {
              root: {
                padding: '6px 16px !important',

                "&:disabled": {
                  backgroundColor: '#D3D3D3 !important',
                  borderColor: "#D3D3D3 !important",
                  color: '#A9A9A9 !important'
                },
                '&:hover': {
                  backgroundColor: primaryDark,
                  color: white + "!important",
                },
              },
              containedPrimary: {
                backgroundColor: primaryDark,
                padding: '6px 16px !important',
                color: white + "!important",
                '&:hover': {
                  backgroundColor: primaryDark,
                  color: white + "!important",
                  border: '1px solid ' + black,

                  //backgroundColor: 'white',
                  //color:primaryDark,
                  outlineColor: 'transparent',
                  outlineStyle: 'solid',
                  boxShadow: '0 0 0 6px transparent',
                  transition: '0.7s',
                },
              },
              outlinedPrimary: {
                color: primaryDark,
                border: '1px solid ' + primaryDark,
                padding: '6px 16px !important',

                '&:hover': {
                  //color: primaryDark,
                  border: '1px solid ' + primaryDark + "!important",
                  outlineColor: 'transparent',
                  outlineStyle: 'solid',
                  boxShadow: '0 0 0 6px transparent',
                  transition: '0.7s',
                },
              }
            }
          },
          MuiAutocomplete: {
            styleOverrides: {
              root: {
                height: '25px',
                padding: '0px'
              },
              tag: {
                backgroundColor: '#E1E4F3',
                borderRadius: 20
              }
            }
          },
          /* MuiFormLabel: {
             root: {
               color: primaryDark,
               fontSize:h3
             },
           },*/
          asterisk: {
            color: "red",
            "&$error": {
              color: "red",
            },
            fontSize: 13,
          },
          // MuiInput: {
          //   styleOverrides: {
          //     underline: {
          //       "&:before": {
          //         borderBottom: "0px solid",
          //         borderColor: "#E0E0E0",
          //       },
          //       "&:after": {
          //         borderBottom: "0px solid",
          //       },
          //       "&&&&:hover:before": {
          //         borderBottom: "0px solid",
          //         borderColor: "#E0E0E0",
          //       },
          //     },
          //   }
          // },
          MuiOutlinedInput: {
            styleOverrides: {
              root: {
                borderRadius: 0,
                border: "1px solid",
                borderColor: '#E0E0E0',
                "&$focused $notchedOutline": {
                  border: "0px solid grey !important"
                },
              },
              input: {
                padding: "4px 6px 6px",
                paddingLeft: "6px !important",
                border: 0,
                textOverflow: "ellipsis",
                alignContent: 'center'
              },
              notchedOutline: {
                border: "0px solid",
              },
            }
          },
          MuiTab: {
            styleOverrides: {
              root: {
                "&$selected": {
                  backgroundColor: "#858AA3",
                  color: "#ECEFF8",
                  "&:hover": {
                    backgroundColor: "#858AA3",
                    color: "#ECEFF8",
                  },
                  borderTopLeftRadius: 7,
                  borderTopRightRadius: 7,
                },
              },
            }
          },
          MuiLink: {
            styleOverrides: {
              root: {
                fontSize: 14,
                borderBottom: "1px solid #445AC7",
                lineHeight: 1,
              },
            }
          },
          MuiFormHelperText: {
            styleOverrides: {
              root: {
                fontSize: 11,
                margin: 9,
              },
            }
          },
          MuiStepIcon: {
            styleOverrides: {
              root: {
                "& $text": {
                  fontSize: 12,
                },
                "&$active": {
                  border: "1px solid",
                  borderColor: primaryDark,
                  padding: 5,
                  margin: -5,
                  borderRadius: 26,
                  backgroundColor: "#FFF",
                  zIndex: 100,
                },
                width: 26,
                height: 26,
              },
            }
          },
          // MuiFormControl: {
          //   styleOverrides: {
          //     root: {
          //       width: "100% !important",
          //     },
          //     filterPanel: {
          //       '& .MuiFormControl-root': {
          //         width: '0px !important', // Adjust as needed
          //       },
          //       '& .MuiDataGrid-filterFormDeleteIcon': {
          //         width: '10px !important',
          //       },
          //     },
          //   }
          // },
          MuiFormControl: {
            styleOverrides: {
              root: {
                // width: "100% !important",
                width: "100% ",
              },
            }
          },
          MuiSelect: {
            styleOverrides: {
              root: {
                marginTop: 0,
                display: 'flex !important',
                alignItems: 'flex-start',
                justifyContent: 'left',
                padding: '4px'
              },
              select: {
                padding: "2px 6px"
              },
              menu: {
                // display: 'block !important',
              },
              paper: {
                display: 'flex !important',
                flexDirection: 'column !important',
              },

            }
          },
          MuiMenuItem: {
            styleOverrides: {
              root: {
                //display: 'inline-flex !important',
                alignItems: 'center !important',
                display: 'flex !important',
                justifyContent: 'left !important',
                padding: '6px 16px !important'
              },
            }
          },
          MuiPopover: {
            styleOverrides: {
              paper: {
                boxShadow:
                  "0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)",
                marginTop: 10,
              },
            }
          },
          MuiFormLabel: {
            styleOverrides: {
              root: {
                color: "#000000",
                fontSize: h3,
              },
              asterisk: {
                color: "red",
                "&$error": {
                  color: "red",
                },

              },
            }
          },

          // MuiOutlinedInput: {
          //   root: {
          //     borderRadius: 0,
          //     border: '1px solid',
          //     borderColor: '#E0E0E0',
          //     "&$focused $notchedOutline": {
          //         "border": "0px solid grey !important"
          //     }
          // },
          // input: {
          //     padding: '8px 0px 9px',
          //     border: 0,
          //     textOverflow: 'ellipsis'
          // },
          // notchedOutline: {
          //     border: '0px solid'
          // }
          // },
          MuiCardHeader: {
            styleOverrides: {
              root: {
                marginTop: 18,
                marginLeft: 12,
                fontSize: h3 + "!important"
              },
            }
          },
          MuiTabs: {
            styleOverrides: {
              indicator: {
                display: "none",
              },
              fixed: {
                borderBottom: "2px solid",
                borderBottomColor: "#858AA3",
              },
              scrollable: {
                borderBottom: "2px solid",
                borderBottomColor: "#858AA3",
                marginBottom: "0px !important",
              },
            }
          },

          // MuiTabs: {
          //   underline: {
          //     "&:before": {
          //       borderBottom: "0px solid",
          //       borderColor: "#E0E0E0",
          //     },
          //     "&:after": {
          //       borderBottom: "0px solid",
          //     },
          //     "&&&&:hover:before": {
          //       borderBottom: "0px solid",
          //       borderColor: "#E0E0E0",
          //     },
          //   },
          // },

          // MuiChip: {
          //   styleOverrides: {

          //     deleteIcon: {
          //       height: 16,
          //       color: "#445AC7",
          //       margin: 0,
          //     },
          //   }
          // },
          MuiChipInput: {
            styleOverrides: {
              root: {
                width: '100% !important',
                padding: 5
              },
              input: {
                width: '100%',
                position: 'relative !important',
                backgroundColor: 'white !important'
              }
            }
          },
          // MuiChip: {
          //   styleOverrides: {
          //     root: {
          //       border: "1px solid" + primaryDark,
          //       color: primaryDark
          //     }
          //   }
          // },
          MuiDataGrid: {
            styleOverrides: {
              root: {
                "& .MuiDataGrid-columnsContainer": {
                  backgroundColor: "#445AC7",
                  color: "white",
                },
                "& .MuiDataGrid-footer": {
                  display: "none",
                },
              },
            }
          },
          MuiFab: {
            styleOverrides: {
              root: {
                color: '#FFFFFF !important',
                border: '2px solid ' + primaryDark + ' !important',
                borderRadius: "20px !important",
                padding: "3px 4px!important",
                fontSize: "15px !important",
                justifyContent: 'space-between'
              }
            }
          },


        },

        shadows: Array(25).fill("none"), //removing shadows globally,
      }),
    [primaryDark]
  );


  useEffect(() => {
    return history.listen((location) => {
      persistor.purge();
    });
  }, [history]);
  useEffect(() => {
    const handleTabOpen = () => {
      const tabsCount = parseInt(localStorage.getItem('tabsCount') || '0', 10) + 1;
      localStorage.setItem('tabsCount', tabsCount.toString());
      if (localStorage.getItem('loginData')) {
        sessionStorage.setItem("loginData", localStorage.getItem("loginData"))
        localStorage.removeItem("loginData")
      }
    };

    const handleTabClose = () => {
      let tabsCount = parseInt(localStorage.getItem('tabsCount') || '1', 10) - 1;
      if (tabsCount < 0) {
        tabsCount = 0;
      }
      localStorage.setItem('tabsCount', tabsCount.toString());
      if (tabsCount === 0) {
        // Clear all storage data when the last tab is closed
        localStorage.clear();
        // Redirect to the login page
        window.location.href = '/login';
      }
    };

    window.addEventListener('DOMContentLoaded', handleTabOpen);
    window.addEventListener('beforeunload', handleTabClose);

    return () => {
      window.removeEventListener('DOMContentLoaded', handleTabOpen);
      window.removeEventListener('beforeunload', handleTabClose);
    };
  }, []);
  useEffect(() => {
    if (document.referrer === "") {
    }
  });
  useEffect(() => {
    setTabCount(localStorage.getItem('tabsOpen'))
  }, [localStorage.getItem('tabsOpen')])




  useEffect(() => {
    console.log(domHistory)
    console.log(props.lockDetails)
  }, [domHistory])

  return (
    <div>
      <style>
        {`
      .e-grid .e-headercell {
        background-color:` + primaryDark + ` !important; /* Replace with your desired color */
      }

      `}
      </style>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={lightTh}>
          <Provider store={store}>
            <Router>
              <PersistGate persistor={persistor}>
                <Suspense fallback={<div>Loading...</div>}>
                  <IdleTimerContainer />
                </Suspense>
                <ToastContainer />
                <Suspense fallback={<div>Loading...</div>}>
                  <RoutesApp />
                </Suspense>
              </PersistGate>
            </Router>
          </Provider>
        </ThemeProvider>
      </StyledEngineProvider>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    profileData: state.profileData.profileData,
    lockDetails: state.LockData.lockedData

  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onRemoveLock: (data) => dispatch(removeLock(data))
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(App);